import { config } from "@config/config";

export const defaultMetaTagData = {
    metaTitle: "Energetická expedice",
    metaDescription: "",
    fbAppId: config.FACEBOOK_APP_ID,
    image: "/images/og-image.jpg",
    keywords: "",
    siteName: "Energetická expedice",
    type: "website",
    imageWidth: 1200,
    imageHeight: 630,
} as const;
