import { createRouter } from "@uxf/router";

export type RouteList = {
    index: null;
    ui: null;
    "sentry-test": null;

    // auth
    "auth/login": { redirect?: string };
    "auth/logout": null;
    "auth/register": null;
    "auth/set-password": { token: string };
    "auth/forgotten-password": null;
    "auth/registration-received": { email: string };
    "auth/registration-pending": { email: string };
    "auth/registration-accepted": { email: string };
    "auth/registration-denied": { email: string };
    "auth/registration-existing-email": { email: string };

    // teacher
    "teacher/launchpad": null;
    "teacher/school-class-list": null;
    "teacher/school-class-create": null;
    "teacher/school-class-update": { id: number };
    "teacher/school-class-students": { id: number };
    "teacher/manual": null;
    "teacher/documents": null;

    // expedition
    "expedition/expedition-map": { schoolClassId: number; tab?: string };
    "expedition/step-detail": { id: number };
    "expedition/results-first-day": { schoolClassId: number };
    "expedition/student-answers": { schoolClassId: number; studentId: number };
    "expedition/expedition-end": { schoolClassId: number };
    "expedition/final-shootout": { schoolClassId: number };
    "expedition/results": { schoolClassId: number };
};

export default createRouter<RouteList>({
    index: "/",
    ui: "/ui",
    "sentry-test": "/sentry-test",

    // auth
    "auth/login": "/prihlaseni",
    "auth/logout": "/odhlaseni",
    "auth/register": "/registrace",
    "auth/set-password": "/nastaveni-hesla/[token]",
    "auth/forgotten-password": "/zapomenute-heslo",
    "auth/registration-received": "/registrace/prijato",
    "auth/registration-pending": "/registrace/ceka-na-potvrzeni",
    "auth/registration-accepted": "/registrace/existujici-ucet",
    "auth/registration-denied": "/registrace/odmitnuto",
    "auth/registration-existing-email": "/registrace/existujici-email",

    // teacher
    "teacher/launchpad": "/launchpad",
    "teacher/school-class-list": "/tridy",
    "teacher/school-class-create": "/tridy/nova",
    "teacher/school-class-update": "/tridy/[id]",
    "teacher/school-class-students": "/tridy/[id]/zaci",
    "teacher/manual": "/manual",
    "teacher/documents": "/podklady-k-tisku",

    // expedition
    "expedition/expedition-map": "/expedice/[schoolClassId]/mapa",
    "expedition/step-detail": "/expedice/krok/[id]",
    "expedition/results-first-day": "/expedice/[schoolClassId]/vysledky/prvni-den",
    "expedition/student-answers": "/expedice/[schoolClassId]/odpovedi/[studentId]",
    "expedition/expedition-end": "/expedice/[schoolClassId]/konec-expedice",
    "expedition/final-shootout": "/expedice/[schoolClassId]/finalni-rozstrel",
    "expedition/results": "/expedice/[schoolClassId]/vysledky",
});
