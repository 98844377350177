import { config } from "@config/config";
import { ICON_SPRITE, ICONS_VERSION } from "@config/icons-config";
import Head from "next/head";
import { memo } from "react";

export const Prefetch = memo(() => (
    <Head>
        {config.GTM_ID && (
            <>
                <link rel="dns-prefetch preconnect" href="https://www.googletagmanager.com" />
                <link rel="dns-prefetch preconnect" href="https://www.google-analytics.com" />
            </>
        )}
        <link as="image" crossOrigin="anonymous" href={`${ICON_SPRITE}?v=${ICONS_VERSION}`} rel="prefetch" />
    </Head>
));

Prefetch.displayName = "Prefetch";
